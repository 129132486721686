<template>
  <div>
    <div class="inbanner clearfix">
      <div class="img"><img :src="information.img"></div>
      <div class="box">
        <div class="span wow fadeInUp"
             data-wow-duration="1.5s"
             data-wow-delay="0.1s">
          {{information.titles?JSON.parse(information.titles)[langtype]||information.title:information.title}}
        </div>
        <div class="bt wow fadeInUp"
             data-wow-duration="1.5s"
             data-wow-delay="0.2s">
          <!--					{{information.name}}-->
          {{information.names?JSON.parse(information.names)[langtype]||information.name:information.name}}
        </div>

        <div class="jsh wow fadeInUp"
             data-wow-duration="1.5s"
             data-wow-delay="0.3s">
          <p>
            <span>
              <!--              {{information.description}}-->
              {{information.descriptions?JSON.parse(information.descriptions)[langtype]||information.description:information.description}}
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="text">
        {{dataList.titles?JSON.parse(dataList.titles)[langtype]||dataList.title:dataList.title}}
      </div>
      <div class="detail-item">
        <div class="author">{{$t('dynamicDetail.zz')}}：{{dataList.authors?JSON.parse(dataList.authors)[langtype]||dataList.author:dataList.author}}</div>
        <div class="details"
             v-html="details"></div>
      </div>
      <div class="case clearfix">
        <div class="containercc">
          <ul class="caselist clearfix wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="0.1s">
            <li v-for="(item,index) in dynamics"
                @click="addCasesImg(item.id)"
                :key="index">
              <a v-if="index<4">
                <div class="box">
                  <div class="pic"><img :src="item.cover_imgs?JSON.parse(item.cover_imgs)[langtype]||item.cover_img:item.cover_img"
                         class="middleCenter"
                         alt="" /></div>
                  <div class="bt">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:item.title}}</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'casesDetail',
  data () {
    return {
      dataList: {},
      details: '',
      information: {},
      dynamics: [],
      langtype: 0
    };
  },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
  },

  mounted () {
    this.addDataList(this.$route.query.id)
    this.getTopImg()
    let nameType = localStorage.getItem("sharing")
    if (nameType == '行业动态') {
      this.companyList(15)
    } else if (nameType == '佳智惠百科') {
      this.companyList(74)
    } else {
      this.companyList(14)
    }
  },

  methods: {
    async addDataList (id) {
      let res = await this.api.get(this.apiPath.dynamicDetail + `${id}` + '/detail', {});
      this.dataList = res
      console.log(this.dataList)
      this.details = res.detailses ? JSON.parse(res.detailses)[this.langtype] || res.details : res.details;
    },
    async getTopImg () {
      let information = await this.api.get(this.apiPath.information, {});
      this.information = information[0]
      console.log(this.information)
    },
    async companyList (id) {
      let data = {
        layout_id: id,
      }
      let companyDynamic = await this.api.post(this.apiPath.editor, data);
      this.dynamics = companyDynamic.items
      console.log(this.dynamics)
    },
    addCasesImg (id) {
      this.addDataList(id)
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style lang="less" scoped>
.inbanner {
  margin-bottom: 40px;
}
.detail {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  .text {
    font-size: 22px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }
  .date {
    font-size: 22px;
    color: #999;
    font-family: arial;
    margin-bottom: 6px;
    text-align: center;
  }
  .author {
    font-size: 14px;
    color: #999;
    font-family: arial;
    margin-bottom: 36px;
    text-align: center;
  }
}
.inmain {
  .box {
    .span {
      font-size: 20px;
      color: #7f0114;
    }
    .bts {
      font-size: 35px;
      font-weight: bold;
      color: #333;
    }
    .fadeInUp {
      text-align: center;
    }
  }
}
@media screen and (max-width: 750px) {
  .detail {
    width: 100%;
  }
  .case {
    display: none;
  }
}
</style>
